<template>
  <v-sheet class="notifiction" id="notifiction">
    <v-row>
      <v-col md="6" class="my-auto">
        <h1 class="custom-header-blue-text m-0">{{ pageTitle() }}</h1>
      </v-col>
      <v-col md="6" class="text-right">
        <v-btn
          :loading="pageLoading"
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          v-on:click="updateOrCreate"
        >
          Save
        </v-btn>
        <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
          Cancel
        </v-btn>
      </v-col>
      <v-col md="8">
        <v-form ref="customerForm" v-on:submit.stop.prevent="updateOrCreate">
          <v-container fluid>
            <v-row>
              <v-col md="3" class="my-auto py-0">
                <label for="title" class="btx-label mt-2 required">Title</label>
              </v-col>
              <v-col md="9" class="py-1">
                <TextInput
                  v-model="notifiction.title"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  :rules="[vrules.required(notifiction.title, 'Title')]"
                  hide-details
                  placeholder="Title"
                  :class="{ required: !notifiction.title }"
                >
                </TextInput>
              </v-col>
              <v-col md="3" class="py-0">
                <label for="text" class="btx-label mt-2">Text</label>
              </v-col>
              <v-col md="9" class="py-0">
                <TextAreaInput
                  v-model="notifiction.text"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  placeholder="Text"
                  :counter="250"
                  :rows="7"
                  :cols="30"
                >
                </TextAreaInput>
              </v-col>
              <v-col md="3" class="my-auto py-0">
                <label for="anchor-url" class="btx-label mt-2 required">Anchor URL</label>
              </v-col>
              <v-col md="9" class="py-1">
                <TextInput
                  v-model="notifiction.anchor_url"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  :rules="[vrules.required(notifiction.anchor_url, 'Anchor URL')]"
                  hide-details
                  placeholder="Anchor URL"
                  :class="{ required: !notifiction.anchor_url }"
                >
                </TextInput>
              </v-col>
              <v-col md="3" class="my-auto py-1">
                <label for="date-of-start" class="btx-label mt-2 required">Blast Date</label>
              </v-col>
              <v-col md="3" class="py-1">
                <DatePicker
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="date-of-start"
                  v-model="notifiction.blast_date"
                  :rules="[vrules.required(notifiction.blast_date, 'Date Of Blast')]"
                  :class="{ require }"
                  placeholder="Blast Date"
                >
                </DatePicker>
              </v-col>
              <v-col cols="6"> </v-col>
              <v-col md="3" class="my-auto py-1">
                <label for="schedule-type" class="btx-label mt-2">Schedule Type</label>
              </v-col>
              <v-col md="3" class="" style="padding: 4px 12px !important">
                <v-chip-group mandatory v-model="notifiction.schedule_type" row>
                  <v-chip
                    :class="{
                      'blue darken-3 white--text': notifiction.schedule_type == 'now',
                    }"
                    label
                    value="now"
                    >Now</v-chip
                  >
                  <v-chip
                    :class="{
                      'blue darken-3 white--text': notifiction.schedule_type == 'later',
                    }"
                    label
                    class="ml-4 p-2"
                    value="later"
                    >Later</v-chip
                  >
                </v-chip-group>
              </v-col>
              <template v-if="notifiction.schedule_type == 'later'">
                <v-col md="12" class="d-flex" style="padding: 0 !important">
                  <v-col md="3" class="my-auto py-0">
                    <label for="segment" class="btx-label mt-2">Schedule Date</label>
                  </v-col>
                  <v-col md="3" class="my-auto py-0">
                    <DatePicker
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      id="date"
                      class="required"
                      placeholder="Date"
                      :rules="[vrules.required(notifiction.schedule_date, 'Schedule Date Time')]"
                      :class="{ required: !notifiction.schedule_date }"
                      v-model="notifiction.schedule_date"
                    ></DatePicker>
                  </v-col>
                  <v-col md="3" class="my-auto py-0 mt-3 text-right">
                    <label for="segment" class="btx-label">Schedule Time</label>
                  </v-col>
                  <v-col md="3" class="my-auto py-0">
                    <v-menu
                      ref="menu"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :return-value.sync="notifiction.time"
                      offset-y
                      class="mt-3"
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="notifiction.time"
                          hide-details
                          outlined
                          prepend-inner-icon="mdi-clock-time-four-outline"
                          readonly
                          placeholder="Select Time"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>

                      <v-time-picker
                        v-if="menu2"
                        v-model="notifiction.time"
                        @click:minute="$refs.menu.save(notifiction.time)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
      <v-col md="4" class="text-center">
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" style="height: 100%; width: 100%">
              <ImageTemplate :src="getProfileImage" :maxHeight="250"></ImageTemplate>
              <v-btn
                :disabled="pageLoading"
                :loading="pageLoading"
                class="white--text mx-2"
                depressed
                color="blue darken-4"
                tile
                style
                v-on:click="fileInput()"
              >
                {{ notifiction.image ? "Reset Image" : "Upload Image" }}
                <v-icon right style="font-size: 21px">
                  {{ `mdi-${notifiction.image ? "refresh" : "cloud-upload-outline"}` }}
                </v-icon>
              </v-btn>
              <div class="mt-3">
                <label for="product-image" class="field-label mt-0 mb-1">
                  Allowed JPG or PNG. Max size of 800K
                </label>
              </div>
            </div>
          </template>
          <span> Upload Images </span>
        </v-tooltip>
      </v-col>
      <v-col md="12" class="pa-0" v-show="false">
        <v-row class="pa-0" style="margin: 0% !important">
          <v-col md="2" sm="3" align="center" justify="center" class="pa-0 mt-2">
            <v-file-input
              id="product-image"
              accept="image/png, image/jpeg, image/bmp"
              ref="fileInput"
              class="d-none"
              v-on:change="updateProfileImage"
            >
            </v-file-input>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="10" class="mt-0 d-flex justify-space-between" v-if="false">
        <v-flex
          md4
          class="custom-box-shadow p-4 grey lighten-5 template"
          style="max-height: 650px; overflow: scroll"
        >
          <v-list class="grey lighten-5" dense>
            <span class="btx-label">Select Template</span>
            <template v-if="filterTemplateLists.length">
              <v-list-item-group v-model="broadcast.selectedTemplate" color="primary" class="mt-3">
                <v-list-item
                  class="py-2 px-4"
                  @click="templateChange(item.template_name)"
                  v-for="(item, index) in filterTemplateLists"
                  :key="index"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ item.template_name }}
                      <div style="font-size: 10px !important" class="mt-1">
                        <span :class="getStatusColor(item.status)"> {{ item.status }}</span>
                      </div>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </template>
            <template v-else>
              <div class="font-level-4-bold mt-6">No Template Available for selection</div>
            </template>
          </v-list>
        </v-flex>
        <v-flex md5 class="custom-box-shadow p-4 mx-12" style="max-height: 650px; overflow: scroll">
          <template v-if="selectedTemplate?.frontend?.is_template_has_params">
            <div
              v-if="
                selectedTemplate?.frontend?.header?.parameters &&
                selectedTemplate?.frontend?.header?.parameters.length
              "
            >
              <span class="btx-label mt-1">Header </span>
              <div
                class="d-flex"
                v-for="(item, index) of selectedTemplate?.frontend?.header?.parameters"
                :key="index"
              >
                <v-flex md3 class="my-auto py-0">
                  <label for="">
                    <template>&#123;&#123;</template>{{ index + 1
                    }}<template>&#125;&#125;</template>
                  </label>
                </v-flex>
                <v-flex md9 class="py-0">
                  <TextInput
                    hide-details
                    :rules="[vrules.required(item.value, `Header Parameter ${index + 1}`)]"
                    :class="{ required: !item.value }"
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    :placeholder="`Header Parameter ${index + 1}`"
                    id="headerPara"
                    v-model="item.value"
                  ></TextInput>
                </v-flex>
              </div>
            </div>
            <div v-if="selectedTemplate?.frontend?.is_file_header">
              <span class="btx-label mt-1"
                >Header File
                <label for="file" class="mt-1" style="text-transform: capitalize">
                  ({{ selectedTemplate?.header_type }})
                </label>
              </span>
              <v-flex md12 class="py-0">
                <FileUpload
                  @input="inputFileImage"
                  id="file"
                  @clearFile="fileRemove"
                  directionColumn
                  :accept="selectedTemplate?.header_type == 'image' ? 'image' : 'application'"
                  :allowAddMore="false"
                  v-model="broadcast.header_image"
                ></FileUpload>
              </v-flex>
            </div>
            <div v-if="selectedTemplate?.frontend?.body?.parameters.length" class="mt-4">
              <span class="btx-label mt-1">Body</span>
              <div
                class="d-flex"
                v-for="(item, bodyIndex) of selectedTemplate?.frontend?.body?.parameters"
                :key="bodyIndex"
              >
                <v-flex md3 class="my-auto py-0">
                  <label for="">
                    <template>&#123;&#123;</template>{{ bodyIndex + 1
                    }}<template>&#125;&#125;</template>
                  </label>
                </v-flex>
                <v-flex md9 class="py-0">
                  <TextInput
                    hide-details
                    :disabled="pageLoading"
                    :rules="[vrules.required(item.value, `Body Parameter ${bodyIndex + 1}`)]"
                    :class="{ required: !item.value }"
                    :loading="pageLoading"
                    :placeholder="`Body Parameter ${bodyIndex + 1}`"
                    id="bodyPara"
                    v-model="item.value"
                  ></TextInput>
                </v-flex>
              </div>
            </div>

            <template v-for="(button, index) of selectedTemplate?.frontend?.buttons">
              <div class="mt-4" v-if="button?.parameters.length" :key="index + 45100">
                <div class="d-flex flex-column">
                  <div class="btx-label mt-1">{{ button?.text }}</div>
                  <div>{{ dynamicButtonUrl(index + 1) }}</div>
                </div>
                <template v-if="button?.parameters.length">
                  <div
                    class="d-flex"
                    v-for="(item, paraIndex) of button?.parameters"
                    :key="paraIndex + 4551"
                  >
                    <v-flex md3 class="my-auto py-0">
                      <label>
                        <template>&#123;&#123;</template>{{ index + 1
                        }}<template>&#125;&#125;</template>
                      </label>
                    </v-flex>
                    <v-flex md9 class="py-0">
                      <TextInput
                        hide-details
                        :disabled="pageLoading"
                        :placeholder="`Url Parameter ${paraIndex + 1}`"
                        :rules="[vrules.required(item.value, `URL Parameter ${paraIndex}`)]"
                        :class="{ required: !item.value }"
                        :loading="pageLoading"
                        id="bodyPara"
                        v-model="item.value"
                      ></TextInput>
                    </v-flex>
                  </div>
                </template>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="d-flex justify-center align-center grey--text">
              <span class="font-level-4-bold">No Parameter Used in Selected Template</span>
            </div>
          </template>
        </v-flex>
        <v-flex md3>
          <MobileFrame>
            <template v-slot:content>
              <div style="border-radius: 1rem; background-color: white">
                <div id="whatsapp-chat">
                  <div class="start-chat">
                    <div
                      pattern="https://elfsight.com/assets/chats/patterns/whatsapp.png"
                      class="WhatsappChat__Component-sc-1wqac52-0 whatsapp-chat-body"
                    >
                      <div class="d-flex flex-column justify-space-between" style="height: 100%">
                        <div
                          class="WhatsappChat__MessageContainer-sc-1wqac52-1 dAbFpq"
                          v-if="false"
                        >
                          <div
                            style="opacity: 1; margin-left: 1px"
                            class="WhatsappChat__Message-sc-1wqac52-4 kAZgZq"
                          >
                            <div class="WhatsappChat__Text-sc-1wqac52-2 iSpIQi">
                              {{ matchingHeader() }}
                            </div>
                            <div
                              class="font-level-1-bold"
                              v-if="
                                header_image_url && this.selectedTemplate.header_type == 'image'
                              "
                            >
                              <v-img
                                v-if="
                                  header_image_url && this.selectedTemplate.header_type == 'image'
                                "
                                style="height: 150px; width: 100%; border-radius: 0.2rem"
                                :src="header_image_url"
                              ></v-img>
                            </div>
                            <span style="display: none"> {{ header_image_url }}</span>

                            <div
                              class="font-level-1-bold grey lighten-4 p-2"
                              style="font-weight: 600; border-radius: 0.2rem"
                              v-if="
                                header_image_url && this.selectedTemplate.header_type == 'document'
                              "
                            >
                              <v-icon color="red">mdi-file-pdf-box</v-icon>
                              <span style="font-weight: 500" class="font-level-2-bold ml-1">
                                {{ template.file[0].name }}
                              </span>
                            </div>
                            <div
                              class="font-level-1-bold"
                              v-if="header_image_url && selectedTemplate?.is_file_header"
                            >
                              <v-img
                                v-if="header_image_url"
                                style="height: 150px; width: 100%; border-radius: 0.2rem"
                                :src="header_image_url"
                              ></v-img>
                            </div>
                            <div class="mt-1">
                              {{ matchingBody() }}
                            </div>
                            <div class="mt-2">
                              {{ selectedTemplate?.frontend?.footer }}
                              <!-- {{button_text_values}} -->
                            </div>

                            <div class="WhatsappChat__Time-sc-1wqac52-5 cqCDVm">Now</div>
                          </div>
                          <template>
                            <div
                              style="margin-top: 4px; border-radius: 0.7rem"
                              class="white px-4 py-1"
                              v-for="(item, index) in selectedTemplate?.frontend?.buttons"
                              :key="index + selectedTemplate?.frontend?.body?.parameters.length"
                            >
                              <div
                                style="font-weight: 600; max-width: calc(100% - 40px)"
                                md="12"
                                class="font-level-2-bold cyan--text d-flex justify-center mx-1"
                              >
                                {{ item.text }}
                              </div>
                            </div>
                          </template>
                        </div>
                        <div style="position: absolute; bottom: 0; z-index: 9999" class="mr-2">
                          <div class="d-flex justify-space-between align-center">
                            <div class="d-flex align-center mr-2 mb-2 custom-input">
                              <v-text-field
                                color="grey"
                                prepend-inner-icon="mdi-emoticon-excited-outline"
                                append-icon="mdi-camera"
                                hide-details
                                label="Type a Message"
                                solo
                                dense
                              ></v-text-field>
                            </div>
                            <div
                              class="d-flex justify-center align-center p-2 mb-2 rounded-xl"
                              style="background: #095e54"
                            >
                              <v-icon size="20" color="white">mdi-microphone</v-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </MobileFrame>
        </v-flex>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { toSafeInteger } from "lodash";
import ImageTemplate from "@/view/components/Image";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import DatePicker from "@/view/components/DatePicker";
import MobileFrame from "@/view/module/marketing/MobileFrame.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CREATE_PROMOTION, UPDATE_PROMOTION, GET_PROMOTION } from "@/core/lib/promotion.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { head, isEmpty } from "lodash";
import moment from "moment-timezone";
import { cloneDeep } from "lodash";

export default {
  name: "notifiction-create",
  title: "Create Notifiction",
  data() {
    return {
      pageLoading: false,
      formValid: true,
      notifictionId: null,
      category_type: 0,
      menu2: false,
      selectedTemplate: null,
      filterTemplateLists: [],
      listCountry: [
        {
          text: "Singapore",
          value: "singapore",
        },
        {
          text: "Malaysia",
          value: "malaysia",
        },
        {
          text: "Australia",
          value: "australia",
        },
      ],
      genderList: [
        {
          text: "Male",
          value: "male",
        },
        {
          text: "Female",
          value: "female",
        },
        {
          text: "Other",
          value: "other",
        },
      ],
      text: "",
      selectedImage: null,
      showModal: false,
      notifiction: {
        title: null,
        text: null,
        blast_date: new Date().toISOString().split("T")[0],
        notifiction: null,
        image: null,
        profile_logo: null,
        schedule_type: "now",
        time: null,
      },
      nowDate: new Date().toISOString().slice(0, 10),
    };
  },
  components: {
    TextInput,
    DatePicker,
    TextAreaInput,
    ImageTemplate,
    MobileFrame,
  },
  methods: {
    pageTitle() {
      if (this.notifictionId) {
        return "Update Notifiction";
      }
      return "Create Notifiction";
    },
    async updateOrCreate() {
      const _this = this;
      if (!_this.$refs.notifictionForm.validate()) {
        return false;
      }

      try {
        _this.pageLoading = true;
        if (_this.promotionId) {
          const promotion = await UPDATE_PROMOTION(_this.promotionId, _this.promotion);
          _this.$router.replace({
            name: "promotion-detail",
            params: { id: promotion.id },
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Promotion Updated Successfully." },
          ]);
        } else {
          const promotion = await CREATE_PROMOTION(_this.promotion);
          _this.$router.replace({
            name: "promotion-detail",
            params: { id: promotion.id },
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Promotion Created Successfully." },
          ]);
        }
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
    async getPromotion() {
      const promotion = await GET_PROMOTION(this.promotionId);
      this.promotion = {
        title: promotion.title,
        description: promotion.description,
        blast_date: promotion.blast_date,
        date_of_exp: promotion.date_of_exp,
        date_of_publish: promotion.date_of_publish,
        image: promotion.images,
      };
      this.$store.dispatch(SET_BREADCRUMB, [
        { text: "Promotion", disabled: true },
        { text: "Update", disabled: true },
        { text: promotion.barcode, disabled: true },
      ]);
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files);
    },
    createImage(files) {
      const vm = this;
      for (var index = 0; index < files.length; index++) {
        var reader = new FileReader();
        reader.onload = function (event) {
          const imageUrl = event.target.result;
          vm.images.push(imageUrl);
        };
        reader.readAsDataURL(files[index]);
      }
    },
    removeImage(index) {
      this.images.splice(index, 1);
    },
    handleFileUpload(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.promotion.images.push({
            file: files[i],
            previewUrl: e.target.result,
          });
        };
        reader.readAsDataURL(files[i]);
      }
    },
    removeImages(index) {
      this.promotion.images.splice(index, 1);
    },
    showImage(index) {
      this.selectedImage = this.images[index];
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedImage = null;
    },
    updateProfileImage(param) {
      const _this = this;
      _this.imageLoading = true;
      _this
        .uploadAttachment(param)
        .then((response) => {
          _this.notifiction.profile_logo = head(response);
          _this.notifiction.image = this.notifiction.profile_logo.id;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },
    fileInput() {
      const { fileInput } = this.$refs;
      if (fileInput && fileInput.$refs) {
        const { input } = fileInput.$refs;
        if (input) {
          input.click();
        }
      }
    },
  },
  computed: {
    minDate() {
      const pastDate = moment(this.notifiction.blast_date, "YYYY-MM-DD").add("days", 1);
      return moment(pastDate).format("YYYY-MM-DD");
    },
    getProfileImage() {
      return isEmpty(this.notifiction.profile_logo) === false
        ? this.notifiction.profile_logo.file.url
        : process.env.VUE_APP_BASE_URL + "media/misc/no_photo_found.png";
    },
    matchingHeader() {
      return () => {
        if (this.selectedTemplate) {
          let string1 = cloneDeep(this.selectedTemplate?.frontend?.header?.string);
          if (this.selectedTemplate?.frontend?.header?.parameters) {
            const headerParameter = cloneDeep(this.selectedTemplate?.frontend?.header?.parameters);
            for (let index = 0; index < headerParameter.length; index++) {
              const item = headerParameter[index];
              if (item.value) {
                const count = index + 1;
                string1 = string1.replaceAll("{{" + count + "}}", item.value);
              }
            }
            return string1;
          }
        }
      };
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Promotion", disabled: true },
      { text: "Create", disabled: true },
    ]);

    const { name, params } = this.$route;
    if (name === "promotion-update") {
      const { id } = params;
      if (id) {
        this.promotionId = toSafeInteger(id);
        this.getPromotion();
      } else {
        // this.goBack();
      }
    }
  },
};
</script>

<style>
.multiple_img {
  width: 30%;
  margin: auto;
  display: block;
  margin-bottom: 10px;
}

.custom-sub-header-blue-text {
  font-weight: 900 !important;
  color: #24326d !important;
  letter-spacing: 1.5px !important;
  line-height: 1.11em !important;
  text-transform: uppercase !important;
}

/* Style for the file input */
input[type="file"] {
  display: none;
}

/* Style for the file input label */
.file_upload {
  padding: 10px 20px;
  background-color: #0d47a1;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.file_upload:hover {
  background-color: #0d47a1 !important;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.image-item {
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
}

.profile-pic {
  width: 90px;
  height: 90px;
  -o-object-fit: cover;
  object-fit: cover;
  cursor: pointer;
  border: 1px solid black;
}

.remove-button {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background-color: #f44336;
  color: white;
  border: none;
  /* border-radius: 50%; */
  cursor: pointer;
  font-weight: bold;
  clip-path: circle();
  border: 7px;
}

.remove-button:hover {
  background-color: #d32f2f;
}

/* Modal styles */
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 800px;
  height: 80%;
}

.modal-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.close {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 35px;
  font-weight: bold;
  color: #f1f1f1;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
button.v-btn.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default.green {
  background-color: rgb(34, 167, 93) !important;
  color: #fff;
}
button.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default.red {
  background-color: rgb(196, 47, 34) !important;
  color: #fff;
}
</style>
