import ApiService from "@/core/services/api.service";

export const GET_PROMOTION = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`promotion`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_PROMOTION = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("add-update-promotion", params)

      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UPDATE_PROMOTION_SEQUENCE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    // ApiService.post("add-update-sequence-promotion", params)
    ApiService.post(`add-update-sequence-promotion`, {
      tables: params,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UPDATE_PROMOTION = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`add-update-promotion/${id}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const FIND_PROMOTION = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`temp-promotion/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_PRIMARY = (param, id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`mark-primary-promotion/${param}`, {
      id: id,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
