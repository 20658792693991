<template>
  <div class="smartphone">
    <div class="content">
      <div class="frame">
        <slot name="content"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "customer-create",
  title: "Create Customer",
};
</script>
